// project importsla
import React, { useContext, useEffect, useRef, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { Autocomplete, Box, Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArticleIcon from '@mui/icons-material/Article';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import HistoryIcon from '@mui/icons-material/History';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import EditIcon from '@mui/icons-material/Edit';
import { JitsiMeeting } from '@jitsi/react-sdk';
import useFetch from 'hooks/useFetch';
import { BASE_URL } from 'constants/baseUrl';
import VitalSign from 'views/components/vitalSign';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import UploadReportComp from 'components/UploadReportComp';
import IntakeHistoryComp from 'components/IntakeHistoryComp';
import VitalSignComp from 'components/VitalSignComp';
import { MeetingContextProvider } from './MeetingContext';
import ModalCustom from 'ui-component/modals/ModalCustom';
import ModalConfirmation from 'ui-component/modals/ModalConfirmation';
import { COLORS } from 'constants/colors';
import { profileImage } from 'utils/fetchImage';
import PatientPrescription from 'components/prescriptionComp';
import PatientDiagnosis from 'components/diagnosisComps';
import PatientLabTest from 'components/labTestComps';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrintSummary from './PrintSummary';
import { AlertPrintPopUp } from 'components/AlertPrintPopUp';
import ViewReportsByPatient from 'components/ViewReportsByPatient';
import PreviousVisitsComp from 'views/components/PreviousVisitsComp';
import MicIcon from '@mui/icons-material/Mic';
// import GPTIcon from 'assets/images/icons/gpt-icon.png';
// import NLPIcon from 'assets/images/icons/nlp-icon.png';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ModalNLP from 'components/nlp/ModalNLP';
import GPT from 'components/gpt';
import TranslationContext from 'context/translation';
import CampVisits from './CampVisits';
import { loadTranscription, startedTranscription } from 'store/slices/transcriptionSlice';
import OrganizeTranscriptionData from 'components/organizeTranscriptionData';
import { getVisitDetail } from 'services/visit';

const MeetingRoom1 = ({ state }) => {
    const { user } = useSelector((state) => state?.user);
    const navigate = useNavigate();
    let { config } = useSelector((state) => state?.config);
    const { transcription, transcriptionStarted } = useSelector((state) => state?.transcription);
    const dispatch = useDispatch();

    const { translate } = useContext(TranslationContext);

    let isHideVisitSummary = config?.find((i) => i.title == 'HideVisitSummary')?.value || null;
    let showVoicePrescription = config?.find((i) => i.title == 'ShowVoicePrescription')?.value || null;
    let showGPTPrescription = config?.find((i) => i.title == 'ShowGPTPrescription')?.value || null;
    const gptRef = useRef();

    const [refetchVital, setRefetchVital] = useState(false);
    const [visit, setVisit] = useState(state);
    const [isMeetingEnded, setIsMeetingEnded] = useState(false);

    const [openHistoryModal, setOpenHistoryModal] = useState(false);
    const [openUploadReportModal, setOpenUploadReportModal] = useState(false);
    const [openViewReportModal, setOpenViewReportModal] = useState(false);
    const [openVitalSignModal, setOpenVitalSignModal] = useState(false);
    const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
    const [openDiagnosisModal, setOpenDiagnosisModal] = useState(false);
    const [openLabTestModal, setOpenLabTestModal] = useState(false);
    const [openPreviousVisits, setOpenPreviousVisits] = useState(false);
    const [openFollowUp, setOpenFollowUp] = useState(false);
    const [openNotes, setOpenNotes] = useState(false);
    const [openPrintModal, setOpenPrintModal] = useState(false);
    const [openMeetingEndModal, setOpenMeetingEndModal] = useState(false);
    const [openAllCampVisitList, setOpenAllCampVisitList] = useState(false);
    const [openOrganizeData, setOpenOrganizeData] = useState(false);
    const [openNlpModal, setOpenNlpModal] = useState(false);
    const [openGPTModal, setOpenGPTModal] = useState(false);
    const [openMicModal, setOpenMicModal] = useState(false);

    const [loadingEndMeeting, setLoadingEndMeeting] = useState(false);

    const [specialityList, setSpecialityList] = useState([]);
    const [openConsultationNeeded, setOpenConsultationNeeded] = useState(false);
    const [followUpDateTime, setFollowUpDateTime] = useState(null);
    const [selectedSpeciality, setSelectedSpeciality] = useState(null);

    const [printByVisit, setPrintByVisit] = useState(false);
    const componentRef = useRef();

    const [notes, setNotes] = useState(visit?.notes || state?.notes);

    const [updateVisitData, setUpdateVisitData] = useState({
        isConsultantRequired: false,
        referredTo: null,
        followupdatetime: null,
        isfollowup: false,
        isActive: false
    });

    const [updatePatientTags, setUpdatePatientTags] = useState({
        followupdatetime: null,
        isfollowup: false,
        IsAppointmentSchedule: false,
        isConsultantappointmentSchedule: false
    });

    const {
        data: specialityDate,
        loading: loadingspecialityDate,
        error: errorspecialityDate,
        refetch: refetchspecialityDate
    } = useFetch(`${BASE_URL}api/colorcode`);

    const handleFollowUpDateTime = () => {
        if (followUpDateTime == null) {
            return toast.error('Please enter follow up date time');
        }
        setUpdateVisitData((pre) => ({ ...pre, isfollowup: true, followupdatetime: followUpDateTime }));
        setUpdatePatientTags((pre) => ({ ...pre, isfollowup: true, followupdatetime: followUpDateTime }));
        setVisit({ ...visit, followupdatetime: followUpDateTime });
        setOpenFollowUp(false);
    };

    const handleConsultationNeeded = () => {
        if (selectedSpeciality == null) {
            return toast.error('Please select any speciality');
        }
        setUpdateVisitData((pre) => ({ ...pre, isConsultantRequired: true, referredTo: selectedSpeciality?.trim() }));
        setOpenConsultationNeeded(false);
    };

    const handleSubmitNotes = async () => {
        try {
            const resUpdateVisit = await axios({
                method: 'put',
                url: `${BASE_URL}api/visit/updateVisit/${visit.id}`,
                data: {
                    notes
                },
                headers: { Authorization: `Bearer ${user?.token}` }
            });
        } catch (error) {
            toast.success(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        }
    };

    const handleEndMeeting = async () => {
        setLoadingEndMeeting(true);
        try {
            const resUpdateVisit = await axios({
                method: 'put',
                url: `${BASE_URL}api/visit/updateVisit/${visit.id}`,
                data: updateVisitData,
                headers: { Authorization: `Bearer ${user?.token}` }
            });

            const resUpdatePtTags = await axios({
                method: 'put',
                url: `${BASE_URL}api/visit/updatePatientTags/${visit.patient_NationalID}`,
                data: updatePatientTags,
                headers: { Authorization: `Bearer ${user?.token}` }
            });

            if (resUpdateVisit.data && resUpdatePtTags.data) {
                toast.success('Meeting Ended Successfully');
                navigate(-1);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadingEndMeeting(false);
        }
    };

    useEffect(() => {
        const triggerPres = async () => {
            const response = await getVisitDetail(visit?.id);
            let v = response?.data[0];
            setVisit(v);
            setNotes(JSON?.parse(v?.transcriptionData)?.plain_text_response);
            setOpenNotes(true);
            dispatch(startedTranscription(false));
        };
        if (transcription !== null && (user?.roles == 'Nurse' || user?.roles == 'Receptionist')) {
            triggerPres();
        }
    }, [transcription]);

    return (
        <MeetingContextProvider visit={visit} setCampVisit={setVisit}>
            <MainCard>
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12} sx={{ mb: 1 }}>
                        <Grid container spacing={1}>
                            {user?.roles !== 'Patient' && (
                                <Grid item lg={2.5} md={2.5} sm={2.5} xs={5} sx={{ display: 'flex' }}>
                                    <Box
                                        sx={{ width: 55, height: 55, borderRadius: 55 / 2, overflow: 'hidden', backgroundColor: '#adadad' }}
                                    >
                                        <img
                                            alt={'Profile'}
                                            src={profileImage(visit?.patientProfileImage)}
                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        />
                                    </Box>

                                    <Box sx={{ ml: 2 }}>
                                        <Typography sx={{ fontSize: 16, fontWeight: '600' }}>
                                            {visit?.patientFirstName} {visit?.patientLastName}
                                        </Typography>
                                        <Typography sx={{ fontSize: 12 }}>
                                            {translate('GENDER')}: {visit?.patientGender}
                                        </Typography>
                                        <Typography sx={{ fontSize: 12 }}>
                                            {translate('AGE')}: {visit?.patientAge}
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                            <Grid item lg={2.5} md={2.5} sm={2.5} xs={5} sx={{ display: 'flex' }}>
                                <Box sx={{ width: 55, height: 55, borderRadius: 55 / 2, overflow: 'hidden', backgroundColor: '#adadad' }}>
                                    <img
                                        alt={'Profile'}
                                        src={profileImage(visit?.consultantProfileImage || visit?.physicianProfileImage)}
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                    />
                                </Box>

                                <Box sx={{ ml: 2 }}>
                                    <Typography sx={{ fontSize: 16, fontWeight: '600' }}>
                                        {visit?.consultantName?.split('/')[0] || visit?.doctorName?.split('/')[0]}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, textTransform: 'capitalize' }}>
                                        {visit?.doctorSpeciality?.toLowerCase()}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item lg={3} md={4.5} xs={4.5} sm={6}>
                                <Typography sx={{ fontSize: 16 }}>
                                    {translate('TITLE')}: {visit?.title}
                                </Typography>
                                {isHideVisitSummary !== 'true' && (
                                    <Typography sx={{ fontSize: 13 }}>
                                        {translate('SUMMARY')}: {visit?.summaryNotes}
                                    </Typography>
                                )}
                            </Grid>
                            {user?.roles !== 'Patient' && (
                                <>
                                    <Grid item lg={3} md={4} xs={4} sm={6}>
                                        {showGPTPrescription && user?.roles == 'Physician' && (
                                            <GPT
                                                ref={gptRef}
                                                visit={visit}
                                                open={true}
                                                isAutoStart={true}
                                                showAudioButton={false}
                                                onGetPrescription={(text) => {
                                                    setNotes(text);
                                                    setOpenNotes(true);
                                                }}
                                            />
                                        )}
                                    </Grid>

                                    <Grid item lg={1} md={1} xs={1} sm={1}>
                                        {showVoicePrescription && (
                                            <IconButton onClick={() => setOpenNlpModal(true)} color="inherit" sx={{ float: 'right' }}>
                                                <RecordVoiceOverIcon />
                                            </IconButton>
                                        )}
                                        {visit?.isCampOn && user?.roles !== 'Physician' && (
                                            <Button
                                                size="small"
                                                fullWidth
                                                variant="contained"
                                                onClick={() => setOpenAllCampVisitList(true)}
                                            >
                                                Next
                                            </Button>
                                        )}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>

                    {/* ==========================================  Top Buttons  =========================================== */}
                    <Grid item lg={2} xs={6}>
                        <Button
                            startIcon={<LocalPrintshopIcon />}
                            fullWidth
                            variant="contained"
                            sx={{ backgroundColor: '#7ac74f', '&: hover': { backgroundColor: '#265427' } }}
                            onClick={() => {
                                setOpenPrintModal(true);
                            }}
                        >
                            {translate('SUMMARY')}
                        </Button>
                    </Grid>
                    <Grid item lg={2} xs={6}>
                        <Button
                            startIcon={<EditIcon />}
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                setOpenVitalSignModal(true);
                            }}
                        >
                            {translate('VITAL_SIGNS')}
                        </Button>
                    </Grid>
                    {user?.roles !== 'Patient' && (
                        <Grid item lg={2} xs={6}>
                            <Button startIcon={<HistoryIcon />} fullWidth variant="contained" onClick={() => setOpenPreviousVisits(true)}>
                                {translate('PREVIOUS_VISITS')}
                            </Button>
                        </Grid>
                    )}
                    <Grid item lg={2} xs={6}>
                        <Button
                            startIcon={<EditIcon />}
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                setOpenHistoryModal(true);
                            }}
                        >
                            {translate('INTAKE_HISTORY')}
                        </Button>
                    </Grid>
                    <Grid item lg={2} xs={6}>
                        <Button
                            startIcon={<UploadFileIcon />}
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                setOpenUploadReportModal(true);
                            }}
                        >
                            {translate('UPLOAD_REPORTS')}
                        </Button>
                    </Grid>
                    <Grid item lg={2} xs={6}>
                        <Button
                            startIcon={<ArticleIcon />}
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                setOpenViewReportModal(true);
                            }}
                        >
                            {translate('VIEW_REPORTS')}
                        </Button>
                    </Grid>
                    {/* ===========================================  Vital Sign and Jitsi Meet  ====================================== */}
                    <Grid item lg={5} xs={12}>
                        <VitalSign visitID={visit?.id} show={true} refetchVital={refetchVital} />
                    </Grid>
                    <Grid item lg={7} xs={12}>
                        <Grid sx={{ backgroundColor: '#adadad', borderRadius: 3, overflow: 'hidden', display: 'flex', minHeight: '370px' }}>
                            {isMeetingEnded ? (
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '370px',
                                        bgcolor: '#f0f0f0',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography fontSize={18}>Your video session is ended!</Typography>

                                    <Button onClick={() => setIsMeetingEnded(false)}>Rejoin</Button>
                                </Box>
                            ) : (
                                <JitsiMeeting
                                    domain={'meet.cloudclinic.ai'}
                                    roomName={visit?.meetinglink}
                                    configOverwrite={{
                                        startWithAudioMuted: true,
                                        disableModeratorIndicator: true,
                                        enableEmailInStats: false,
                                        disableSimulcast: false
                                    }}
                                    interfaceConfigOverwrite={{
                                        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                                        filmStripOnly: false,
                                        SHOW_JITSI_WATERMARK: false,
                                        SHOW_WATERMARK_FOR_GUEST: false
                                    }}
                                    userInfo={{
                                        displayName: user?.firstName
                                    }}
                                    getIFrameRef={(iframeRef) => {
                                        iframeRef.style.flex = 1;
                                    }}
                                    onReadyToClose={() => setIsMeetingEnded(true)}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {/* ===========================================  Bottom Button  =============================================== */}
                    {user?.roles !== 'Patient' && (
                        <>
                            <Grid item lg={2} xs={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{ backgroundColor: '#7ac74f', '&: hover': { backgroundColor: '#265427' } }}
                                    onClick={() => setOpenFollowUp(true)}
                                >
                                    {translate('FOLLOWUP')}
                                </Button>
                            </Grid>
                            <Grid item lg={2} xs={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{ backgroundColor: '#7ac74f', '&: hover': { backgroundColor: '#265427' } }}
                                    onClick={() => {
                                        setOpenConsultationNeeded(true);
                                        setSpecialityList([...new Set(specialityDate?.map((item) => item.speciality))]);
                                    }}
                                >
                                    {translate('CONSULTATION')}
                                </Button>
                            </Grid>
                            <Grid item lg={2} xs={6}>
                                <Button fullWidth variant="contained" onClick={() => setOpenPrescriptionModal(true)}>
                                    {translate('PRESCRIPTION')}
                                </Button>
                            </Grid>
                            <Grid item lg={2} xs={6}>
                                <Button fullWidth variant="contained" onClick={() => setOpenDiagnosisModal(true)}>
                                    {translate('DIAG_PLAN')}
                                </Button>
                            </Grid>
                            <Grid item lg={2} xs={4}>
                                <Button fullWidth variant="contained" onClick={() => setOpenLabTestModal(true)}>
                                    {translate('LAB_TEST')}
                                </Button>
                            </Grid>
                            <Grid item lg={1} xs={4}>
                                <Button fullWidth variant="contained" onClick={() => setOpenNotes(true)}>
                                    {translate('NOTES')}
                                </Button>
                            </Grid>
                            <Grid item lg={1} xs={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{ backgroundColor: '#FF2E2E', '&: hover': { background: '#c7020c' } }}
                                    onClick={() => setOpenMeetingEndModal(true)()}
                                >
                                    {translate('END')}
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </MainCard>

            {/* ===========================================  Modals Section  =========================================== */}

            {/* -----------------------  History Modal  -------------------------------------- */}
            {openHistoryModal && (
                <IntakeHistoryComp
                    open={openHistoryModal}
                    onClose={() => {
                        setOpenHistoryModal(false);
                    }}
                    isHistoryUpdated={(val, historyStatus, close) => {
                        if (val == true) {
                            setVisit({ ...visit, historystatus: historyStatus });
                            close && setOpenHistoryModal(false);
                        }
                    }}
                    visit={visit}
                />
            )}

            {/* -----------------------  Vital Sign Modal  -------------------------------------- */}
            {openVitalSignModal && (
                <VitalSignComp
                    open={openVitalSignModal}
                    onClose={() => {
                        setOpenVitalSignModal(false);
                    }}
                    isVitalSignUpdated={(val) => {
                        if (val == true) {
                            setVisit({ ...visit, vitalsignStatus: 'VitalSigns filled' });
                            setRefetchVital(!refetchVital);
                            setOpenVitalSignModal(false);
                            // getVitalsByVisit();
                        }
                    }}
                    visit={visit}
                />
            )}

            {/* -----------------------  Upload Reports Modal  -------------------------------------- */}
            {openUploadReportModal && (
                <UploadReportComp
                    open={openUploadReportModal}
                    onClose={() => {
                        setOpenUploadReportModal(false);
                    }}
                    visit={visit}
                />
            )}

            {/* -----------------------  View Reports Modal  -------------------------------------- */}
            {openViewReportModal && (
                <ViewReportsByPatient
                    open={openViewReportModal}
                    onClose={() => {
                        setOpenViewReportModal(false);
                    }}
                    visit={visit}
                />
            )}

            {/* -----------------------  Patient Prescription Modal  -------------------------------------- */}
            {openPrescriptionModal && (
                <PatientPrescription
                    open={openPrescriptionModal}
                    onClose={() => {
                        setOpenPrescriptionModal(false);
                    }}
                    visit={visit}
                />
            )}

            {/* -----------------------  Patient Diagnosis Modal  -------------------------------------- */}
            {openDiagnosisModal && (
                <PatientDiagnosis
                    open={openDiagnosisModal}
                    onClose={() => {
                        setOpenDiagnosisModal(false);
                    }}
                    visit={visit}
                />
            )}

            {/* -----------------------  Patient Lab Test Modal  -------------------------------------- */}
            {openLabTestModal && (
                <PatientLabTest
                    open={openLabTestModal}
                    onClose={() => {
                        setOpenLabTestModal(false);
                    }}
                    visit={visit}
                />
            )}

            {/* -----------------------  Organize Data Modal  -------------------------------------- */}
            {openOrganizeData && (
                <OrganizeTranscriptionData
                    open={openOrganizeData}
                    onClose={() => {
                        setOpenOrganizeData(false);
                    }}
                    visit={visit}
                />
            )}
            {transcriptionStarted && <ModalConfirmation open={transcriptionStarted} loading={true} />}
            {/* ------------------------  Modal NLP  -------------------------- */}
            {/* {openMicModal && (
                <ModalCustom open={openMicModal} title={'Speech to Text'}>
                    <Box>
                        <IconButton
                            color="inherit"
                            onClick={() => setOpenMicModal(false)}
                            sx={{ position: 'absolute', top: 10, right: 10 }}
                        >
                            <CloseIcon />
                        </IconButton>

                        <Grid container spacing={2}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Box onClick={() => setOpenGPTModal(true)} sx={styles.btnStyle}>
                                    <img alt={'GPT Icon'} src={GPTIcon} style={styles.btnIcon} />
                                </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Box onClick={() => setOpenNlpModal(true)} sx={styles.btnStyle}>
                                    <img alt={'NLP Icon'} src={NLPIcon} style={styles.btnIcon} />
                                </Box>
                            </Grid>
                        </Grid>

                        {openNlpModal && <ModalNLP visit={visit} open={openNlpModal} onClose={() => setOpenNlpModal(false)} />}
                        {openGPTModal && <GPT visit={visit} open={openGPTModal} onClose={() => setOpenGPTModal(false)} />}
                    </Box>
                </ModalCustom>
            )} */}
            {openNlpModal && <ModalNLP visit={visit} open={openNlpModal} onClose={() => setOpenNlpModal(false)} />}

            {/* -----------------------  Previous Visit Modal  -------------------------------------- */}

            {openPreviousVisits && (
                <ModalCustom open={openPreviousVisits} title={'Previous Visits'}>
                    <Box>
                        <IconButton
                            color="inherit"
                            onClick={() => setOpenPreviousVisits(false)}
                            sx={{ position: 'absolute', top: 10, right: 10 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <PreviousVisitsComp patient_NationalID={visit.patient_NationalID} />
                    </Box>
                </ModalCustom>
            )}

            {/* ------------------------  Modal Notes  -------------------------- */}
            <ModalCustom open={openNotes} title={'Notes'}>
                <Box sx={{ width: '35vw' }}>
                    <TextField
                        fullWidth
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        multiline
                        variant="outlined"
                        label="Add Notes"
                        rows={5}
                        sx={{ mb: 2 }}
                    />

                    <Box sx={styles.btnContainer}>
                        <Button
                            disabled={
                                visit?.transcriptionData ? (JSON?.parse(visit?.transcriptionData)?.json_response ? false : true) : true
                            }
                            onClick={() => {
                                handleSubmitNotes();
                                setOpenOrganizeData(true);
                                setOpenNotes(false);
                            }}
                            variant="text"
                            sx={{ color: COLORS.secondory }}
                        >
                            Refine Transcription
                        </Button>

                        <Button
                            onClick={() => {
                                setOpenNotes(false);
                                dispatch(loadTranscription(null));
                            }}
                            variant="text"
                            sx={{ color: 'red' }}
                        >
                            Cancel
                        </Button>

                        <Button
                            onClick={() => {
                                handleSubmitNotes();
                                setOpenNotes(false);
                            }}
                            variant="text"
                            sx={{ color: COLORS.secondory }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </ModalCustom>

            {/* ------------------------  Modal Follow Up  -------------------------- */}
            <ModalCustom open={openFollowUp} title={'Follow Up'}>
                <Box sx={{ width: '20vw' }}>
                    <TextField
                        fullWidth
                        type="date"
                        variant="outlined"
                        label="Follow Up Date"
                        InputLabelProps={{ shrink: true }}
                        sx={{ my: 2 }}
                        InputProps={{
                            inputProps: {
                                min: new Date().toLocaleDateString('en-CA')
                            }
                        }}
                        value={followUpDateTime}
                        onChange={(e) => setFollowUpDateTime(e?.target?.value)}
                    />

                    <Box sx={styles.btnContainer}>
                        <Button onClick={() => setOpenFollowUp(false)} variant="text" sx={{ color: 'red' }}>
                            Cancel
                        </Button>

                        <Button onClick={handleFollowUpDateTime} variant="text" sx={{ color: COLORS.secondory }}>
                            Submit
                        </Button>
                    </Box>
                </Box>
            </ModalCustom>

            {/* ------------------------  Modal Consultation Needed  -------------------------- */}
            <ModalCustom open={openConsultationNeeded} title={'Consultation'}>
                <Box sx={{ width: '20vw' }}>
                    <Autocomplete
                        sx={{ my: 2 }}
                        options={specialityList}
                        getOptionLabel={(item) => `${item}`}
                        value={specialityList?.find((i) => i == selectedSpeciality)}
                        onChange={(event, selected) => {
                            setSelectedSpeciality(selected || null);
                        }}
                        renderInput={(params) => <TextField {...params} label="Consult with" variant="outlined" />}
                    />

                    <Box sx={styles.btnContainer}>
                        <Button onClick={() => setOpenConsultationNeeded(false)} variant="text" sx={{ color: 'red' }}>
                            Cancel
                        </Button>

                        <Button onClick={handleConsultationNeeded} variant="text" sx={{ color: COLORS.secondory }}>
                            Submit
                        </Button>
                    </Box>
                </Box>
            </ModalCustom>

            {/* --------------------------  Confirmation for End Meeting  --------------------------- */}
            <ModalConfirmation
                open={openMeetingEndModal}
                header={'End Meeting'}
                description={'Are you sure, you want to end this meeting?'}
                loading={loadingEndMeeting}
                onClickNo={() => setOpenMeetingEndModal(false)}
                onClickYes={() => handleEndMeeting()}
            />

            {/* -----------------------  Camp Visit Modal  -------------------------------------- */}

            {openAllCampVisitList && (
                <ModalCustom open={openAllCampVisitList} title={'Camp Visits'}>
                    <Box sx={{ minWidth: '50vw' }}>
                        <IconButton
                            color="inherit"
                            onClick={() => setOpenAllCampVisitList(false)}
                            sx={{ position: 'absolute', top: 10, right: 10 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <CampVisits
                            visit={visit}
                            setVisit={setVisit}
                            updatePatientTags={updatePatientTags}
                            updateVisitData={updateVisitData}
                            onClose={() => setOpenAllCampVisitList(false)}
                        />
                    </Box>
                </ModalCustom>
            )}

            {/* ----------------------------  Print Summary Comp  ------------------------------ */}
            <div style={{ height: '0px', width: '0px', overflow: 'hidden' }}>
                <PrintSummary ref={componentRef} visit={visit} printByVisit={printByVisit} />
            </div>

            <ModalCustom open={openPrintModal} title={translate('PRINT_SUMMARY')}>
                <IconButton color="inherit" onClick={() => setOpenPrintModal(false)} sx={{ position: 'absolute', top: 10, right: 10 }}>
                    <CloseIcon />
                </IconButton>

                <AlertPrintPopUp
                    selectedPrint={printByVisit}
                    onSelectedPrint={(e) => setPrintByVisit(e.target.value)}
                    content={() => componentRef.current}
                />
            </ModalCustom>
        </MeetingContextProvider>
    );
};

export default MeetingRoom1;

const styles = {
    btnContainer: {
        display: 'flex',
        direction: 'row',
        gap: 1,
        justifyContent: 'flex-end'
    },
    btnIcon: {
        width: 80,
        height: 80
    },
    btnStyle: {
        border: '1px solid #d5d5d5',
        backgroundColor: '#fff',
        transition: '0.4s',
        width: 110,
        height: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 1,
        borderRadius: 2,
        '&:hover': {
            backgroundColor: '#d1e7ff',
            cursor: 'pointer',
            transition: '0.4s'
        }
    }
};
